<template>
  <div v-if="Object.keys(config_footer).length != 0" id="remoteConfigurableFooter">
    <component
      :is="currentFooter"
      :footer="config_footer"
      :media="config_media"
      :adaptiveType="the_adaptiveType"
    ></component>
  </div>
</template>

<script>
const SimpleFooter = () => import("./simple.vue");
const ComplexFooter = () => import("./complex.vue");
const XDFooter = () => import("./xd-cn.vue");

export default {
  components: { SimpleFooter, ComplexFooter, XDFooter },
  props: {
    app_id: {
      type: String | Number,
      default: () => ""
    },
    language: {
      type: String,
      default: () => ""
    },
    adaptiveType: {
      type: String,
      default: () => ""
    },
    ccTLD: {
      type: String,
      default: () => ""
    },
    "__intro__": {
      type: String,
      default: () => "以上参数会默认读取moo, 手动传参则覆盖; ccTLD( .cn国内 .com国外 )"
    }
  },
  data() {
    return {
      commonConfig: {},
      the_app_id: 0,
      the_language: "__default",
      the_adaptiveType: "px",
      the_region: "overseas",
      currentFooter: "",
      config_footer: {},
      config_media: []
    }
  },
  watch: {
    language: {
      handler(newVal, oldVal) {
        if(newVal && newVal != oldVal) {
          if(this.commonConfig && Object.keys(this.commonConfig).length == 0) return;

          this.the_language = newVal;
          this.handleConfig();
        }
      }
    }
  },
  created() {
    // 通过判断location的后缀域名，决定是否显示国内页脚
    if(location.hostname.includes(".cn")) {
      this.the_region = "domestic"
    }else {
      this.the_region = "overseas"
    }

    // 默认读取moo下的配置（建站器输出的页面，都有moo对象）
    if(window.moo) {
      this.the_app_id = window.moo['app_id'] || '';
      this.the_language = window.moo['language'] || '';
      this.the_adaptiveType = window.moo['adaptiveType'] || '';
    }

    // props 优先 （如果在建站器外部使用，可以手动传参）
    if(this.$props['app_id']) {
      this.the_app_id = this.$props['app_id'];
    }
    if(this.$props['language']) {
      this.the_language = this.$props['language'];
    }
    if(this.$props['adaptiveType']) {
      this.the_adaptiveType = this.$props['adaptiveType'];
    }
    if(this.$props['ccTLD']) {
      if(this.$props['ccTLD'].includes(".cn")) {
        this.the_region = "domestic"
      }else {
        this.the_region = "overseas"
      }
    }

    if(this.the_app_id) {
      // 匹配到有效的app_id, 会去查找配置
      this.reqConfig()
    } else {
      // 设置为默认配置
      this.setDefault()
    }
  },
  methods: {
    reqConfig() {
      fetch(`https://website.xdcdn.net/form/website/common-config/common_${this.the_app_id}.json`)
        .then(res => res.json())
        .then(data => {
          this.commonConfig = data;
          this.handleConfig();
        })
        .catch(() => {
          // 当未查询到app_id对应的json时, 读取默认配置
          this.setDefault()
        })
    },
    setDefault() {
      let config_default = require("./default.json")

      if(this.the_region == 'overseas') {
        // 海外默认页脚
        this.config_footer = config_default["overseas__default"]['footer']
        this.currentFooter = "SimpleFooter"
      } else {
        // 国内默认页脚
        this.config_footer = config_default["domestic__default"]['footer']
        this.currentFooter = "XDFooter"
      }
    },
    handleConfig() {
      const data = this.commonConfig;

      const config_default = data['__default'] || {};
      const config_lang = data[this.the_language] || config_default;

      // 读取语言配置，未找到配置则读取默认配置
      let config_temp = {};
      Object.keys(config_lang).forEach(item => {
        if(Array.isArray(config_lang[item])) {
          // 处理数组
          config_temp[item] = [];

          if (config_lang[item] && config_lang[item].length != 0) {
            config_temp[item] = config_lang[item]
          } else {
            config_temp[item] = config_default[item]
          }
        } else {
          // 处理对象
          config_temp[item] = {};

          Object.keys(config_lang[item]).forEach(key => {
            let this_config = config_lang[item][key];
            if(this_config && Array.isArray(this_config) && this_config.length != 0) {
              config_temp[item][key] = this_config;
            } else if(this_config && !Array.isArray(this_config)) {
              config_temp[item][key] = this_config;
            } else {
              config_temp[item][key] = config_default[item][key]
            }
          })
        }
      })

      this.config_footer = config_temp['footer'] || {};
      this.config_media = config_temp['media'] || [];

      // 关于使用国内页脚还是国外页脚, 读取json配置最优先
      let region = data['region'] || "";
      if(region) {
        this.the_region = region;
      }

      // 设置页脚模版
      if(this.the_region == 'overseas') {
        // 海外页脚
        if(
          this.config_footer['list_prompt'] &&
          this.config_footer['list_prompt'].length != 0
        ) {
          this.currentFooter = "ComplexFooter"
        }else {
          this.currentFooter = "SimpleFooter"
        }
      } else {
        // 国内页脚
        this.currentFooter = "XDFooter"
      }
    }
  }
}
</script>

<style lang="scss" scoped>
#remoteConfigurableFooter {
  position: relative;
  width: 100%;
}
</style>